import { action, computed, makeObservable, observable } from 'mobx';

type TServerValidationError = {
  message: string;
  attrName: string;
};

type TRecordsErrors = Record<number, TServerValidationError[]>;

export type TServerValidationErrors = Record<string, TRecordsErrors>;

export class ErrorsValidationErrorsStore {
  @observable private _errors: TServerValidationErrors | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get errors(): TServerValidationErrors | null {
    return this._errors;
  }

  @action.bound
  removeRecordErrors(objectType: string, recordId: number): void {
    const objectTypeErrors = this._errors?.[objectType];

    if (!objectTypeErrors || !this._errors) {
      return;
    }

    const { [recordId]: deletingRecord, ...rest } = objectTypeErrors;

    this._errors[objectType] = {
      ...rest,
    };
  }

  @action.bound
  setErrors(errors: TServerValidationErrors | null): void {
    this._errors = errors;
  }
}
