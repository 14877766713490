import { action, computed, flow, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';

import { agent } from 'src/api/agent';
import { ApiError, OperationAbortedError } from 'src/packages/errors';
import { getAttrKey } from 'src/packages/utils/get-attr-key';
import { getRandomNumber } from 'src/packages/utils/get-random-number';
import { hasValue } from 'src/packages/utils/has-value';
import { numberGenerator } from 'src/packages/utils/number-generator';

import type { DirectoryService } from './directory-service.interface';
import type { IControl, TControlView, TDirectoryObjectData, TRecord, TTableView } from './types';
import type {
  IDirectoriesStorage,
  TGetObjectsParams,
} from '../directories-storage-service/directories-storage-service';
import type { LocalizationService } from '../localization-service.ts';
import type { INotificationsService } from '../notifications-service';
import type { TableSettingsService } from '../table-settings-service/table-settings-service';
import type { DirectoryTableMediator } from 'src/components/directory-table/directory-table-mediator';
import type { TSavedEditModeObject } from 'src/pages/directory/edit-mode-manager';

import { ControlRulesService } from '../control-rules-service';
import { TableSettingsManager } from '../table-settings-service/table-settings-manager';

import { DirectoriesServiceApi } from './api/directories-service-api';
import { deserializeDirectoryRecord } from './directory-service.utils';
import { DirectoryRecord } from './entities/directory-record.entity';
import { createControl } from './mappers/create-control';
import { getControlsViews } from './mappers/get-controls-views';
import { OptionsService } from './options-service';
import { getObjectTypesFromControlView } from './utils';
import { ValidationService } from './validation-service';

interface IEditModeDataManager {
  saveEditModeData: (directory: MainDirectoryService) => void;
  getSavedEditModeData: (directory: MainDirectoryService) => TSavedEditModeObject | null;
}

export class MainDirectoryService implements DirectoryService<TTableView> {
  @observable private importAbortController: AbortController | null = null;

  private readonly editModeDataManager: IEditModeDataManager;
  private readonly api: DirectoriesServiceApi;
  readonly localizationService: LocalizationService;
  readonly notificationsService: INotificationsService;
  readonly directoriesStorageService: IDirectoriesStorage;
  readonly tableSettingsService: TableSettingsService;
  readonly optionsService: OptionsService;
  readonly validationService: ValidationService;

  readonly view: TTableView;
  readonly directoryName: string;
  readonly entityId: string = nanoid(6);
  readonly controlRulesService: ControlRulesService;
  readonly numberGenerator: () => number = numberGenerator('negative');

  @observable generalControls: IControl[] = [];
  @observable records: DirectoryRecord[] = [];
  readonly recordsMap = observable.map<number, DirectoryRecord>();
  @observable recordsActivity: Record<number, boolean> = {};

  @observable recordControlsViews: TControlView[] = [];
  @observable generalControlsViews: TControlView[] = [];
  controlsViewsMap = observable.map<string, TControlView>();

  @observable isDataFetching = false;
  @observable isImporting = false;
  @observable isAdditionalDirectoriesFetching = false;
  abortControllers = observable.map<string, VoidFunction>();
  @observable tableSettingsManager: TableSettingsManager;
  @observable directoryViewMediator: DirectoryTableMediator | null = null;
  readonly isImportAvailable: boolean;

  @observable.ref status:
    | { type: 'initial' }
    | { type: 'ready' }
    | { type: 'error'; message: 'generalControlsNotFilledIn' | 'noData' } = { type: 'initial' };

  constructor(
    editModeDataManager: IEditModeDataManager,
    directoriesStorageService: IDirectoriesStorage,
    tableSettingsService: TableSettingsService,
    notificationsService: INotificationsService,
    localizationService: LocalizationService,
    directoryName: string,
    view: TTableView,
  ) {
    this.editModeDataManager = editModeDataManager;
    this.tableSettingsService = tableSettingsService;
    this.localizationService = localizationService;
    this.directoriesStorageService = directoriesStorageService;
    this.optionsService = new OptionsService(notificationsService, directoriesStorageService);
    this.validationService = new ValidationService(localizationService);
    this.notificationsService = notificationsService;
    this.view = view;
    this.directoryName = directoryName;
    this.tableSettingsManager = new TableSettingsManager(tableSettingsService, directoryName);
    this.controlRulesService = new ControlRulesService(directoriesStorageService, this.validationService);
    this.isImportAvailable = !!view.allowImport;
    this.api = new DirectoriesServiceApi(agent);

    makeObservable(this);
  }

  @computed
  get generalControlsValues(): Record<string, unknown> {
    const values: Record<string, unknown> = {};

    for (const control of this.generalControls) {
      if (!control.attrName) {
        continue;
      }

      if (hasValue(control.value)) {
        const attrKey = getAttrKey(control.attrName);

        values[attrKey] = control.value;
      }
    }

    return values;
  }

  getRecordById(id: number): DirectoryRecord | null {
    const record = this.recordsMap.get(id) ?? null;

    return record;
  }

  @flow.bound
  async *loadAdditionalDirectory(): Promise<void> {
    this.isAdditionalDirectoriesFetching = true;

    const tableSettings = await this.tableSettingsService.getOrCreateSettings();
    const columnsVisibility = tableSettings?.[this.directoryName]?.columnsVisibility ?? {};
    const controlViews = [...this.generalControlsViews, ...this.recordControlsViews];

    if (!controlViews.length) {
      return;
    }

    const loadObjects = async (objectType: string) => {
      try {
        await this.directoriesStorageService.loadObjects(objectType, true);
      } catch (e) {
        if (e instanceof OperationAbortedError) {
          return;
        }

        console.error(e);

        if (e instanceof ApiError && e.message) {
          this.notificationsService.showErrorMessage(e.message);
          return;
        }

        this.notificationsService.showErrorMessageT('directory:errors.data.failedToLoadData');
      }
    };

    const requests: Promise<void>[] = [];

    for (const controlView of controlViews) {
      // временно отключенный функционал
      // if (columnsVisibility[refObjectType] === false) {
      //   continue;
      // }

      const objectTypes = getObjectTypesFromControlView(controlView);

      objectTypes.forEach((refObjectType) => {
        requests.push(loadObjects(refObjectType));
      });
    }

    await Promise.allSettled(requests);
    yield;

    this.isAdditionalDirectoriesFetching = false;
  }

  @computed
  get hasChanges(): boolean {
    return this.records.some((record) => record.recordChangeState);
  }

  @computed
  get newRecords(): DirectoryRecord[] {
    return this.records.filter((record) => record.recordChangeState === 'new');
  }

  @computed
  get updatedRecords(): DirectoryRecord[] {
    return this.records.filter((record) => record.recordChangeState === 'hasChanges');
  }

  @computed
  get areGeneralControlsFilledIn(): boolean {
    if (!this.generalControls.length) {
      return true;
    }

    return this.generalControls.every((control) => hasValue(control.value));
  }

  @action.bound
  onGeneralControlValueChange(control: IControl, value: unknown): void {
    control.setError(null);
    control.setValue(value);

    this.tableSettingsService.getOrCreateSettings().then((settings) => {
      this.tableSettingsService.updateSettings(this.directoryName, {
        ...settings?.[this.directoryName],
        fieldValues: Object.fromEntries(this.generalControls.map(({ fieldId, value }) => [fieldId, value])),
      });
    });

    this.loadData();
  }

  @action.bound
  onControlBlur(control: IControl): void {
    this.validationService.validateControl(control);
  }

  @action.bound
  onControlValueChange(record: DirectoryRecord, control: IControl, value: unknown): void {
    if (value === control.value) {
      return;
    }

    control.setError(null);
    control.setValue(value);

    this.controlRulesService.onControlValueChange(
      record.controlsMap,
      control.fieldId,
      value,
      (control: IControl, value: unknown) => {
        this.onControlValueChange(record, control, value);
      },
    );

    this.editModeDataManager.saveEditModeData(this);
  }

  @action.bound
  cancelImport(): void {
    this.importAbortController?.abort();
    this.importAbortController = null;
  }

  @flow.bound
  async *importData(file: File, onSuccess?: () => void) {
    this.isImporting = true;
    const abortController = new AbortController();
    this.importAbortController = abortController;

    try {
      const formData = new FormData();
      formData.append('file', file);

      await this.api.importData(formData);
      yield;

      this.loadData();
      onSuccess?.();
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      console.error(e);
      this.notificationsService.showErrorMessageT('Не удалось импортировать данные');
    } finally {
      this.isImporting = false;
      this.importAbortController = null;
    }
  }

  @flow.bound
  async *initializeControls() {
    const { recordControls, topControls } = getControlsViews(this.view);
    const controls = [...recordControls, ...topControls];

    this.recordControlsViews = recordControls;
    this.generalControlsViews = topControls;
    this.controlRulesService.mapRulesFromControlViews(controls);

    controls.forEach((controlView) => {
      this.controlsViewsMap.set(controlView.fieldId, controlView);

      const localeKey = `labels:${controlView.fieldId}.label`;
      const defaultLabel = controlView.defaultLabel || controlView.defaultValue;

      if (!this.localizationService.i18n.exists(localeKey) && defaultLabel) {
        this.localizationService.i18n.languages.forEach((lang) => {
          this.localizationService.i18n.addResource(lang, 'labels', `${controlView.fieldId}.label`, defaultLabel);
        });
      }
    });

    await this.loadAdditionalDirectory();
    yield;

    this.generalControls = topControls.map((controlView) => createControl(controlView, this.view.required));

    this.optionsService.createInitialOptions(controls);
    this.validationService.setControlViews(controls);
    this.validationService.setRequiredFields(this.view.required);

    const settings = await this.tableSettingsService.getOrCreateSettings();
    yield;

    if (settings) {
      this.generalControls.forEach((control) => {
        const savedValue = settings[this.directoryName]?.fieldValues?.[control.fieldId];

        if (!hasValue(control.value) && hasValue(savedValue)) {
          control.setValue(savedValue);
        }
      });
    }

    this.status = { type: 'ready' };
  }

  @action.bound
  discardChanges(): void {
    const initialRecords: DirectoryRecord[] = [];

    for (const record of this.records) {
      record.controls.forEach((c) => c.setError(null));

      if (record.recordChangeState === 'new') {
        continue;
      }

      if (record.recordChangeState === 'hasChanges') {
        record.controls.forEach((control) => {
          this.onControlValueChange(record, control, control.initialValue);
          control.setError(null);
        });
        initialRecords.push(record);
        continue;
      }

      initialRecords.push(record);
    }

    this.records = initialRecords;
  }

  @action.bound
  resetRecord(record: DirectoryRecord): void {
    if (record.recordChangeState !== 'hasChanges') {
      console.error('deleting record is not hasChanges');
      return;
    }

    record.controls.forEach((control) => this.onControlValueChange(record, control, control.initialValue));
    this.editModeDataManager.saveEditModeData(this);
  }

  @action.bound
  deleteRecord(record: DirectoryRecord): void {
    if (record.recordChangeState !== 'new') {
      console.error('deleting record is not new');
      return;
    }

    const index = this.records.indexOf(record);

    if (index >= 0) {
      this.records.splice(index, 1);
      this.recordsMap.delete(record.recordId);
      this.directoryViewMediator?.onDeleteRecord?.(record);
    }

    this.editModeDataManager.saveEditModeData(this);
  }

  @action.bound
  createRecords(initialValues: Partial<TRecord>[]): DirectoryRecord[] {
    const records: DirectoryRecord[] = [];
    const activity: Record<number, boolean> = {};
    const recordsMap = new Map<number, DirectoryRecord>();

    const requiredRecords = this.validationService.requiredFields;

    initialValues.forEach((initRecord) => {
      const id = initRecord?.id ?? -getRandomNumber();

      const controls = this.recordControlsViews.map((control) => {
        const initialValue = initRecord?.[control.fieldId];

        return createControl(control, requiredRecords, initialValue);
      });

      activity[id] = true;

      const newRecord = new DirectoryRecord(controls, id);

      newRecord.controls.forEach((control) => {
        this.controlRulesService.onControlValueChange(
          newRecord.controlsMap,
          control.fieldId,
          control.initialValue ?? null,
          (control: IControl, value: unknown) => {
            this.onControlValueChange(newRecord, control, value);
          },
        );
      });

      records.push(newRecord);

      recordsMap.set(id, newRecord);
    });

    this.recordsActivity = { ...this.recordsActivity, ...activity };
    this.recordsMap.merge(recordsMap);

    for (const newRecord of records) {
      this.records.push(newRecord);
    }

    this.editModeDataManager.saveEditModeData(this);

    return records;
  }

  @action.bound
  addNewRecord(initialValue?: Partial<TRecord>): DirectoryRecord {
    const id = initialValue?.id ?? -getRandomNumber();

    const controls = this.recordControlsViews.map((control) => {
      const savedValue = control.attrName ? initialValue?.[control.attrName] : undefined;

      return createControl(control, this.validationService.requiredFields, savedValue);
    });

    this.recordsActivity[id] = true;

    const newRecord = new DirectoryRecord(controls, id);
    this.records.push(newRecord);

    this.directoryViewMediator?.onAddNewRecord?.(newRecord);

    this.recordsMap.set(id, newRecord);

    this.editModeDataManager.saveEditModeData(this);

    return newRecord;
  }

  @action.bound
  setDirectoryViewMediator(mediator: DirectoryTableMediator | null): void {
    this.directoryViewMediator = mediator;
  }

  @flow.bound
  async *updateDirectoryStatus(status: boolean, recordId: number) {
    const newStatus = status ? 'ACTIVE' : 'DELETED';

    this.recordsActivity[recordId] = status;

    try {
      await this.directoriesStorageService.changeObjectStatus(this.directoryName, recordId, newStatus);
      yield;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.data.failedToUpdateStatus');
    }
  }

  @flow.bound
  async *loadData(params?: TGetObjectsParams) {
    if (!this.areGeneralControlsFilledIn) {
      this.status = { type: 'error', message: 'generalControlsNotFilledIn' };

      return;
    } else {
      this.status = { type: 'ready' };
    }

    const copiedParams: TGetObjectsParams = {
      ...params,
      filterMap: { ...params?.filterMap, ...this.generalControlsValues },
    };

    this.isDataFetching = true;

    this.abortControllers.get('loadData')?.();

    const abortController = new AbortController();
    this.abortControllers.set('loadData', () => abortController.abort());

    try {
      const data = await this.directoriesStorageService.loadObjects<TDirectoryObjectData>(
        this.directoryName,
        false,
        copiedParams,
        abortController.signal,
      );
      yield;

      const newRecordsActivity: Record<number, boolean> = {};

      const editModeData = this.editModeDataManager.getSavedEditModeData(this);
      const records: DirectoryRecord[] = [];

      data.forEach((record) => {
        const controlValues = deserializeDirectoryRecord(record.data, this.recordControlsViews);

        const controls = this.recordControlsViews.map((controlView) => {
          const initialValue = controlView.attrName ? controlValues?.[controlView.attrName] : undefined;

          return createControl(controlView, this.validationService.requiredFields, initialValue);
        });
        newRecordsActivity[record.id] = record.status === 'ACTIVE';
        const newRecord = new DirectoryRecord(controls, record.id);

        newRecord.controls.forEach((control) => {
          this.controlRulesService.onControlValueChange(
            newRecord.controlsMap,
            control.fieldId,
            control.initialValue ?? null,
            (control: IControl, value: unknown) => {
              this.onControlValueChange(newRecord, control, value);
            },
          );
        });

        const editModeRecordData = editModeData?.updatedRecords[newRecord.recordId];
        const editModeControlValues = editModeRecordData
          ? deserializeDirectoryRecord(editModeRecordData, this.recordControlsViews)
          : null;

        if (editModeControlValues) {
          for (const control of newRecord.controls) {
            if (!control.attrName) {
              continue;
            }

            control.setValue(editModeControlValues[control.attrName]);

            this.controlRulesService.onControlValueChange(
              newRecord.controlsMap,
              control.fieldId,
              editModeControlValues[control.attrName] ?? null,
              (control: IControl, value: unknown) => {
                this.onControlValueChange(newRecord, control, value);
              },
            );
          }
        }

        records.push(newRecord);
        this.recordsMap.set(newRecord.recordId, newRecord);
      });

      if (editModeData) {
        for (const recordId in editModeData.newRecords) {
          const id = Number(recordId);
          const rawRecord = editModeData.newRecords[recordId];
          const controlValues = deserializeDirectoryRecord(rawRecord, this.recordControlsViews);

          const controls = this.recordControlsViews.map((control) => {
            const initialValue = control.attrName ? controlValues?.[control.attrName] : undefined;

            return createControl(control, this.validationService.requiredFields, initialValue);
          });
          newRecordsActivity[id] = true;
          const newRecord = new DirectoryRecord(controls, id);

          newRecord.controls.forEach((control) => {
            this.controlRulesService.onControlValueChange(
              newRecord.controlsMap,
              control.fieldId,
              control.initialValue ?? null,
              (control: IControl, value: unknown) => {
                this.onControlValueChange(newRecord, control, value);
              },
            );
          });

          records.push(newRecord);
          this.recordsMap.set(id, newRecord);
        }
      }

      this.recordsActivity = newRecordsActivity;
      this.records = records;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      console.error(e);

      this.status = { type: 'error', message: 'noData' };

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.data.failedToLoadData');
    } finally {
      this.isDataFetching = false;
    }
  }

  init = (): VoidFunction => {
    const optionsServiceDisposer = this.optionsService.init();

    this.tableSettingsManager.checkSettings(this.controlsViewsMap);

    return () => {
      optionsServiceDisposer();
      this.abortControllers.forEach((fn) => fn());
      this.abortControllers.clear();
    };
  };
}
