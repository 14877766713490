import { action, computed, flow, makeObservable, observable } from 'mobx';

import type { INotificationsService } from '../notifications-service';
import type { AxiosInstance } from 'axios';
import type { UserSettingsService } from 'src/services/user-settings-service/user-settings-service';

import { DirectoriesListApi } from './directories-list-service.api';

export type TDirectoriesType = 'all' | 'favorites';

export type TDirectory = {
  objectType: string;
  title: string;
  visible?: boolean;
  items?: TDependentDirectory[];
};

export type TDependentDirectory = {
  mainAttr: string;
  mainDisplayAttr: string;
  objectType: string;
  refAttr: string;
};

export type TDirectoryGroup = {
  title: string;
  items: TDirectory[];
  visible?: boolean;
};

export class DirectoriesListService {
  private readonly api: DirectoriesListApi;
  private readonly notifications: INotificationsService;
  private readonly userSettings: UserSettingsService;

  @observable currentDirectory: string | null = null;
  @observable currentSubDirectory: string | null = null;
  @observable filterType: TDirectoriesType;
  @observable directoriesByGroups: TDirectoryGroup[] = [];
  @observable isLoading: boolean = false;
  @observable isDirectoryChangingBlocked = false;
  directoriesMap = observable.map<string, TDirectory>();

  constructor(agent: AxiosInstance, notifications: INotificationsService, userSettings: UserSettingsService) {
    this.filterType = 'all';
    this.api = new DirectoriesListApi(agent);
    this.notifications = notifications;
    this.userSettings = userSettings;

    makeObservable(this);
  }

  @computed
  get currentDirectoryView(): TDirectory | null {
    if (!this.currentDirectory) {
      return null;
    }

    return this.directoriesMap.get(this.currentDirectory) ?? null;
  }

  @computed
  get subDirectoryViews(): TDependentDirectory[] | null {
    if (!this.currentDirectoryView) {
      return null;
    }

    const view = this.currentDirectoryView.items;

    return view ?? null;
  }

  @computed
  get currentSubDirectoryView(): TDependentDirectory | null {
    if (!this.currentSubDirectory || !this.subDirectoryViews) {
      return null;
    }

    return this.subDirectoryViews.find((view) => view.objectType === this.currentSubDirectory) ?? null;
  }

  @computed
  get favoritesObjectTypes(): string[] {
    return this.userSettings.settings?.settings.favoriteDirectories || [];
  }

  @computed
  get groupedFavoriteDirectories(): TDirectoryGroup[] {
    if (!this.favoritesObjectTypes.length) {
      return [];
    }

    const newFavoriteDirectories: TDirectoryGroup[] = [];

    this.directoriesByGroups.forEach((group) => {
      const favoritesDirectories = group.items.filter((item) => this.favoritesObjectTypes.includes(item.objectType));

      if (favoritesDirectories.length > 0) {
        newFavoriteDirectories.push({ ...group, items: favoritesDirectories });
      }
    });

    return newFavoriteDirectories;
  }

  @flow.bound
  private async *loadAllDirectories(): Promise<void> {
    this.isLoading = true;

    try {
      const data = await this.api.getAllDirectories();
      yield;

      this.directoriesByGroups = data;
      data.forEach((group) => {
        group.items.forEach((directory) => this.directoriesMap.set(directory.objectType, directory));
      });

      this.userSettings.getOrCreateSettings();
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('directories:errors.failedToLoadDirectoriesList');
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  updateFavoriteDirectories(objectType: string, shouldIncludeToFavorite: boolean): void {
    const newFavoriteList = this.userSettings.settings?.settings.favoriteDirectories
      ? [...this.userSettings.settings?.settings.favoriteDirectories]
      : [];

    if (shouldIncludeToFavorite) {
      newFavoriteList.push(objectType);
    } else {
      const index = newFavoriteList.indexOf(objectType);

      if (index >= 0) {
        newFavoriteList.splice(index, 1);
      }
    }

    this.userSettings.updateSettings({ favoriteDirectories: newFavoriteList });
  }

  @action.bound
  setCurrentDirectory(directoryName: string | null): void {
    this.currentDirectory = directoryName;
  }

  @action.bound
  setCurrentSubDirectory(directoryName: string | null): void {
    this.currentSubDirectory = directoryName;
  }

  @action.bound
  setFilterType(filterType: TDirectoriesType): void {
    this.filterType = filterType;
  }

  @action.bound
  setIsDirectoryChangingBlocked(isBlocked: boolean): void {
    this.isDirectoryChangingBlocked = isBlocked;
  }

  init(): void {
    this.loadAllDirectories();
  }
}
