import { requireService } from '@profgeosoft/di-ez';
import { BackLight } from '@profgeosoft-ui/icons';
import { Button, Loader, Scrollbar } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { wrap } from 'src/packages/mobx-di/wrap';
import { hasValue } from 'src/packages/utils/has-value';

import styles from './models-list.module.scss';

export const ModelsList = wrap(function ModelsList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modelId } = useParams();
  const directoriesList = requireService('directoriesListService');
  const directoryPage = requireService('directoryPage');

  const { directoryModelService, isEditing } = directoryPage;
  const view = directoryModelService?.view;

  const headingText = view ? t(`labels:${view.mainDisplayAttr}.label`) : '';

  const onBack = () => {
    if (!directoriesList.currentDirectory) {
      navigate('/');
      return;
    }

    navigate(`/${directoriesList.currentDirectory}`);
  };

  const onModelClick = (modelId: number) => {
    if (modelId === directoryModelService?.currentModelId) {
      return;
    }

    const isNewModelSet = directoryModelService?.setCurrentModel(modelId);

    if (isNewModelSet) {
      navigate(modelId.toString());
    }
  };

  useEffect(() => {
    return directoryModelService?.init();
  }, [directoryModelService]);

  useEffect(() => {
    if (!modelId && directoryModelService && directoryModelService.modelsList.length) {
      const defaultModelId = directoryModelService.modelsList[0].value;
      const isNewModelSet = directoryModelService.setCurrentModel(defaultModelId);

      if (isNewModelSet) {
        navigate(defaultModelId.toString());
      }
      return;
    }

    if (directoryModelService && directoryModelService.modelsList.length) {
      const modelIdAsNumber = Number(modelId);
      const isExisting = !!directoryModelService.modelsList.find((model) => model.value === modelIdAsNumber);

      if (isExisting) {
        const isNewModelSet = directoryModelService.setCurrentModel(modelIdAsNumber);

        if (isNewModelSet) {
          navigate(modelIdAsNumber.toString());
        }
      } else {
        const isNewModelSet = directoryModelService.setCurrentModel(null);

        if (isNewModelSet) {
          navigate('./');
        }
      }
    }
  }, [modelId, directoryModelService, directoryModelService?.modelsList, navigate]);

  return (
    <>
      <div className={styles.wrapper}>
        {!directoryModelService || directoryModelService.isModelsLoading ? (
          <Loader />
        ) : (
          <>
            <h4 className={styles.heading}>{headingText}</h4>
            <div className={styles.listWrapper}>
              <Scrollbar>
                <ul className={styles.list}>
                  {directoryModelService.modelsList.map(({ label, value }) => {
                    const isActive = value === directoryModelService.currentModelId;
                    const errorCount = directoryModelService.errorsCountByModelId[value];

                    return (
                      <button
                        key={value}
                        className={clsx(styles.listButton, isActive && styles.listButton_active)}
                        // disabled={isEditing}
                        onClick={() => onModelClick(value)}
                      >
                        <p className={styles.listButton_text} title={label}>
                          {label}
                        </p>
                        {hasValue(errorCount) && errorCount > 0 && <div className={styles.errorInfo}>{errorCount}</div>}
                      </button>
                    );
                  })}
                </ul>
              </Scrollbar>
            </div>
          </>
        )}
        <Button className={styles.backButton} icon={<BackLight />} disabled={isEditing} onClick={onBack}>
          {t('common:back')}
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <Outlet />
      </div>
    </>
  );
});
