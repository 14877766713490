import type { TControlView } from './types';

export function getObjectTypesFromControlView(controlView: TControlView): string[] {
  const objectTypes = new Set<string>();

  const processValueSource = (controlView: TControlView) => {
    const stringifiedView = JSON.stringify(controlView);

    Array.from(stringifiedView.matchAll(/"sourceDirectory":\s?"([A-z0-9]+)"/g)).map((tuple) =>
      objectTypes.add(tuple[1]),
    );
  };

  if ('refObjectType' in controlView) {
    objectTypes.add(controlView.refObjectType);
  }

  processValueSource(controlView);

  return Array.from(objectTypes);
}
