import { requireService } from '@profgeosoft/di-ez';
import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { agent } from 'src/api/agent';
import { DirectoryModelService } from 'src/features/models-list/directory-model-service';
import { ApiError, OperationAbortedError, UpdateObjectListError } from 'src/packages/errors';
import { hasValue } from 'src/packages/utils/has-value';
import { isObjectWithKeys } from 'src/packages/utils/is-object-with-keys';
import { AdditionalDirectoryService, MainDirectoryService } from 'src/services/directory-service';
import { TableSettingsService } from 'src/services/table-settings-service/table-settings-service';

import type { TServerValidationErrors } from './server-validation-errors-store';
import type { NavigateFunction } from 'react-router-dom';
import type {
  TComplexFormView,
  TFormView,
  TSimpleFormView,
  TTableView,
} from 'src/services/directory-service/types/views.type';

import { EditModeManager, isSavedDirectoryObject } from './edit-mode-manager';
import { ErrorsValidationErrorsStore } from './server-validation-errors-store';

const isSimpleForm = (view: TFormView): view is TSimpleFormView => {
  return !!view && 'popup' in view;
};

type TServerValidationError = {
  message: string;
  attrName: string;
};

export class DirectoryPageService {
  readonly tableSettings: TableSettingsService;
  readonly editModeManager: EditModeManager;
  readonly serverValidationErrorsStore: ErrorsValidationErrorsStore;
  @observable private mainDirectoryAbortFn: VoidFunction | null = null;

  @observable directoryModelService: DirectoryModelService | null = null;
  @observable currentDirectory: MainDirectoryService | null = null;
  @observable additionalDirectories: AdditionalDirectoryService[] = [];
  @observable isEditing = false;
  @observable isRowSelectionModeOn: boolean = false;
  @observable isAdditionalDirectoryInitializing = false;
  @observable isMainDirectoryInitialized = false;

  constructor(
    private readonly viewService = requireService('viewsService'),
    private readonly directoriesList = requireService('directoriesListService'),
    private readonly notificationsService = requireService('notificationsService'),
    private readonly directoriesStorageService = requireService('directoriesStorage'),
    private readonly localizationService = requireService('localizationService'),

    userService = requireService('userService'),
  ) {
    this.tableSettings = new TableSettingsService(agent, userService, notificationsService);
    this.editModeManager = new EditModeManager(directoriesList);
    this.serverValidationErrorsStore = new ErrorsValidationErrorsStore();

    makeObservable(this);
  }

  @action.bound
  setIsRowSelectionMode(value: boolean): void {
    this.isRowSelectionModeOn = value;
  }

  @flow.bound
  private async *initializeMainDirectory({
    directory,
    subDirectory,
  }: {
    directory: string | null;
    subDirectory: string | null;
  }) {
    this.isMainDirectoryInitialized = false;

    const directoryName = subDirectory ?? directory;
    const directoryType = subDirectory ? 'subDirectory' : 'default';

    this.currentDirectory = null;

    if (!directoryName) {
      return;
    }

    this.mainDirectoryAbortFn?.();

    const viewInst = this.viewService.getView<TTableView>(directoryName, 'table');
    this.mainDirectoryAbortFn = viewInst.cancelLoading;

    try {
      const view = await viewInst.loadView();
      yield;

      const MDSavedEditModeCallbacks = {
        getSavedEditModeData: (directory: MainDirectoryService) =>
          this.editModeManager.getSavedEditModeData(directory, this.directoryModelService?.currentModelId ?? undefined),
        saveEditModeData: (directory: MainDirectoryService) => {
          if (!this.isEditing) {
            return;
          }

          this.editModeManager.saveEditModeData(directory, this.directoryModelService ?? undefined);
        },
      };

      const directoryInst = new MainDirectoryService(
        MDSavedEditModeCallbacks,
        this.directoriesStorageService,
        this.tableSettings,
        this.notificationsService,
        this.localizationService,
        directoryName,
        view,
      );
      this.currentDirectory = directoryInst;

      await directoryInst.initializeControls();
      yield;

      if (directoryType === 'default') {
        directoryInst.loadData();
      }

      if (directoryType === 'subDirectory') {
        this.directoryModelService = new DirectoryModelService(
          this.notificationsService,
          this.directoriesList,
          this.directoriesStorageService,
          directoryInst,
          this.serverValidationErrorsStore,
          this.editModeManager,
        );
      } else {
        this.directoryModelService = null;
      }

      this.isMainDirectoryInitialized = true;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      console.error(e);

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.view.failedToLoadView');
    } finally {
      this.mainDirectoryAbortFn = null;
    }
  }

  @computed
  get hasChanges(): boolean {
    const data = this.editModeManager.savedData;

    if (!data) {
      return false;
    }

    for (const key in data) {
      const dirData = data[key];

      if (typeof dirData !== 'object' || !dirData) {
        continue;
      }

      if (isObjectWithKeys(dirData.newRecords) || isObjectWithKeys(dirData.updatedRecords)) {
        return true;
      }

      if (isSavedDirectoryObject(dirData)) {
        continue;
      }

      for (const modelKey in dirData) {
        const modelData = dirData[modelKey];

        if (typeof modelData !== 'object' || !modelData) {
          continue;
        }

        if (isObjectWithKeys(modelData.newRecords) || isObjectWithKeys(modelData.updatedRecords)) {
          return true;
        }
      }
    }

    return false;
  }

  removeSavedEditModeData(): void {
    this.editModeManager.removeSavedData();
  }

  @action.bound
  addAdditionalDirectory(directoryName: string, view: TComplexFormView): void {
    const directory = new AdditionalDirectoryService(
      this.directoriesStorageService,
      this.notificationsService,
      this.localizationService,
      directoryName,
      view,
    );

    this.additionalDirectories = [...this.additionalDirectories, directory];
  }

  @flow.bound
  async *createNewRelatedRecord(objectName: string): Promise<void> {
    const viewInst = this.viewService.getView<TFormView>(objectName, 'form');
    this.isAdditionalDirectoryInitializing = true;

    try {
      const view = await viewInst.loadView();
      yield;

      if (isSimpleForm(view)) {
        // TODO: здесь должна быть обработка односвойственных директорий (быстрое создание записи из выпадашки комбобокса)
        return;
      } else {
        this.addAdditionalDirectory(objectName, view);
      }
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      console.error(e);

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.view.failedToLoadView');
    } finally {
      this.isAdditionalDirectoryInitializing = false;
    }
  }

  @flow.bound
  async *loadData() {
    const loadFn = this.directoryModelService?.loadTableData || this.currentDirectory?.loadData;

    await loadFn?.();
    yield;
  }

  @flow.bound
  async *saveChanges() {
    const currentDirectory = this.currentDirectory;

    if (!currentDirectory) {
      return;
    }

    if (!currentDirectory.areGeneralControlsFilledIn) {
      return;
    }

    currentDirectory.isDataFetching = true;

    let isRecordsValid = true;

    const editModeData = this.editModeManager.savedData?.[currentDirectory.directoryName];

    if (!editModeData || typeof editModeData !== 'object') {
      console.error('invalid edit mode data');
      return;
    }

    [...currentDirectory.newRecords, ...currentDirectory.updatedRecords].forEach((record) => {
      if (!currentDirectory.validationService.validateRecord(record)) {
        isRecordsValid = false;
      }
    });

    if (!isRecordsValid) {
      this.notificationsService.showErrorMessageT('directory:errors.validation.invalidRecords');
      currentDirectory.isDataFetching = false;
      return;
    }

    const recordIdsMap = new Map<number, number>();

    try {
      // Scheme:
      // {
      //   record_index_in_result_array: record_id
      // }
      const recordsObjects = (() => {
        if (isSavedDirectoryObject(editModeData)) {
          const { newRecords, updatedRecords } = editModeData;
          const finalRecords: { id?: number; data: Record<string, unknown> }[] = [];

          for (const recordId in newRecords) {
            const newLength = finalRecords.push({
              data: { ...newRecords[recordId], ...currentDirectory.generalControlsValues },
            });

            recordIdsMap.set(newLength - 1, Number(recordId));
          }

          for (const recordId in updatedRecords) {
            const newLength = finalRecords.push({
              id: Number(recordId),
              data: { ...updatedRecords[recordId], ...currentDirectory.generalControlsValues },
            });

            recordIdsMap.set(newLength - 1, Number(recordId));
          }

          return finalRecords;
        }

        const finalRecords: { id?: number; data: Record<string, unknown> }[] = [];

        if (!this.directoryModelService?.view) {
          throw new Error('model view is not presented');
        }

        for (const modelId in editModeData) {
          const modelIdAsANumber = Number(modelId);
          const { newRecords, updatedRecords } = editModeData[modelIdAsANumber];

          for (const recordId in newRecords) {
            const newLength = finalRecords.push({
              data: {
                ...newRecords[recordId],
                ...currentDirectory.generalControlsValues,
                [this.directoryModelService?.view?.refAttr]: modelIdAsANumber,
              },
            });

            recordIdsMap.set(newLength - 1, Number(recordId));
          }

          for (const recordId in updatedRecords) {
            const newLength = finalRecords.push({
              id: Number(recordId),
              data: { ...updatedRecords[recordId], ...currentDirectory.generalControlsValues },
            });

            recordIdsMap.set(newLength - 1, Number(recordId));
          }
        }

        return finalRecords;
      })();

      await this.directoriesStorageService.updateObjectList(currentDirectory.directoryName, recordsObjects);
      yield;

      this.removeSavedEditModeData();
      this.setIsEditMode(false);
      this.setIsRowSelectionMode(false);
      this.loadData();
    } catch (e) {
      yield;

      if (e instanceof UpdateObjectListError) {
        if (e.message) {
          this.notificationsService.showErrorMessage(e.message);
        }

        // Set errors from response to controls.
        if (e.violations) {
          if (!this.currentDirectory?.directoryName) {
            return;
          }

          const errorsObject: TServerValidationErrors = {
            [this.currentDirectory.directoryName]: {},
          };

          for (const { index, message, attrName } of e.violations) {
            const idOfRecordWithError = recordIdsMap.get(index);

            if (!hasValue(message) || !hasValue(idOfRecordWithError)) {
              continue;
            }

            if (!errorsObject[currentDirectory.directoryName][idOfRecordWithError]) {
              errorsObject[currentDirectory.directoryName][idOfRecordWithError] = [];
            }

            errorsObject[currentDirectory.directoryName][idOfRecordWithError].push({ attrName, message });
          }

          this.serverValidationErrorsStore.setErrors(errorsObject);
        }

        return;
      }

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);

        return;
      }

      console.error(e);
      this.notificationsService.showErrorMessageT('directory:errors.data.failedToUpdateDirectory');
    } finally {
      yield;
      currentDirectory.isDataFetching = false;
    }
  }

  @action.bound
  setIsEditMode(isEditing: boolean): void {
    this.isEditing = isEditing;
  }

  @action.bound
  removeLastAdditionalDirectory(): void {
    this.additionalDirectories = this.additionalDirectories.slice(0, this.additionalDirectories.length - 1);
  }

  init = (navigateFn: NavigateFunction) => {
    this.tableSettings.init();

    const directoryServiceDisposer = reaction(
      () => ({
        directory: this.directoriesList.currentDirectory,
        subDirectory: this.directoriesList.currentSubDirectory,
      }),
      this.initializeMainDirectory,
      { fireImmediately: true, name: 'mainDirectoryInitializer' },
    );

    const savedEditModeData = this.editModeManager.init();

    if (savedEditModeData) {
      let path = `/${savedEditModeData.lastVisitedDirectory}`;

      if (savedEditModeData.lastVisitedSubDirectory) {
        path = `${path}/${savedEditModeData.lastVisitedSubDirectory}`;
      }

      if (hasValue(savedEditModeData.lastVisitedModelId)) {
        path = `${path}/${savedEditModeData.lastVisitedModelId}`;
      }

      navigateFn(path);

      this.setIsEditMode(true);
      this.setIsRowSelectionMode(true);
    }

    const serverValidationErrorsDisposer = reaction(
      () => ({
        modelId: this.directoryModelService?.currentModelId,
        errors: this.serverValidationErrorsStore.errors,
        directoryName: this.currentDirectory?.directoryName,
        updatedRecords: this.currentDirectory?.updatedRecords,
        newRecords: this.currentDirectory?.newRecords,
      }),
      ({ errors, updatedRecords, newRecords, directoryName }) => {
        if (!this.isEditing) {
          this.serverValidationErrorsStore.setErrors(null);
        }

        if (
          !this.isEditing ||
          !errors ||
          !directoryName ||
          (!updatedRecords && !newRecords) ||
          (!updatedRecords?.length && !newRecords?.length)
        ) {
          return;
        }

        [...(updatedRecords ?? []), ...(newRecords ?? [])].forEach((record) => {
          const errorsObjects = errors[directoryName]?.[record.recordId];

          if (errorsObjects) {
            errorsObjects.forEach(({ attrName, message }) => {
              const controlWithError = record.controls.find((c) => c.attrKey === attrName);

              controlWithError?.setError(message ?? null);
            });

            this.serverValidationErrorsStore.removeRecordErrors(directoryName, record.recordId);
          }
        });
      },
    );

    return () => {
      directoryServiceDisposer();
      this.mainDirectoryAbortFn?.();
      serverValidationErrorsDisposer();
    };
  };
}
