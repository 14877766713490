import { type ObservableMap } from 'mobx';

import { hasValue } from 'src/packages/utils/has-value';

import type {
  IDirectoriesStorage,
  TStorageObject,
} from 'src/services/directories-storage-service/directories-storage-service';
import type { IControl, TDirectorySource, TValueSourceObject } from 'src/services/directory-service/types';

import { compareDataWithFilterItems } from './compare-data-with-filter-items';
import { mapFilterRuleItems } from './map-filter-rule-items';

export const handleValueSourceRule = (
  directoriesStorageService: IDirectoriesStorage,
  controlsMap: ObservableMap<string, IControl<unknown>>,
  sources: TValueSourceObject | TValueSourceObject[],
): { value: unknown; valuesByPseudonims: Record<string, unknown> } => {
  const valuesByPseudonims: Record<string, unknown> = {};

  function processNestedSource(sourceObj: TDirectorySource, value: unknown): unknown {
    const directory = directoriesStorageService.directories[sourceObj.sourceDirectory];

    if (!directory) {
      console.error(`cannot find corresponding directory with given directory name ${sourceObj.sourceDirectory}`);
      return null;
    }

    const filters = sourceObj.filter
      ? mapFilterRuleItems(sourceObj.filter, directoriesStorageService, controlsMap)
      : [];

    const filteredDirectory = directory.filter((d) => {
      const { data, ...rest } = d;
      const dirData: Record<string, unknown> = { ...rest, ...data };

      return compareDataWithFilterItems(dirData, filters);
    });

    const processDirectoryRecord = (directoryRecord: TStorageObject) => {
      const recordValue = (() => {
        if (sourceObj.source === 'id') {
          return directoryRecord.id;
        }

        return sourceObj.source ? directoryRecord.data[sourceObj.source] : directoryRecord;
      })();

      if (sourceObj.pseudonim) {
        valuesByPseudonims[sourceObj.pseudonim] = recordValue;
      }

      if (!hasValue(recordValue)) {
        return null;
      }

      return sourceObj.isSourceFor ? processNestedSource(sourceObj.isSourceFor, recordValue) : recordValue;
    };

    if (Array.isArray(value)) {
      const filteredResult: unknown[] = [];

      for (const valueItem of value) {
        const directoryRecord = (() => {
          if (typeof valueItem === 'object' && valueItem !== null) {
            return valueItem;
          }

          return filteredDirectory.find((record) => record.id === valueItem);
        })() as TStorageObject;

        if (!directoryRecord) {
          continue;
        }

        filteredResult.push(processDirectoryRecord(directoryRecord));
      }

      return filteredResult;
    } else {
      const directoryRecord = (() => {
        if (typeof value === 'object' && value !== null) {
          return value;
        }

        return filteredDirectory.find((record) => record.id === value);
      })() as TStorageObject;

      if (!directoryRecord) {
        return null;
      }

      return processDirectoryRecord(directoryRecord);
    }
  }

  function getValueFromSources(sourceObj: TValueSourceObject): unknown {
    const filters = sourceObj.filter
      ? mapFilterRuleItems(sourceObj.filter, directoriesStorageService, controlsMap)
      : [];

    if (sourceObj.sourceType === 'directory') {
      if (sourceObj.isConstDirectory) {
        // добавить поддержку директорий-констант
        console.warn('константные справочники пока что не поддерживаются');
        return null;
      }

      const directory = directoriesStorageService.directories[sourceObj.sourceDirectory];

      if (!directory) {
        console.error('не удалось найти директорию', sourceObj.sourceDirectory);
        return null;
      }

      const value = (() => {
        if (sourceObj.source) {
          const filteredDirectoryValues: unknown[] = [];

          const source = sourceObj.source;

          directory.forEach((d) => {
            const { data, ...rest } = d;
            const dirData: Record<string, unknown> = { ...rest, ...data };

            if (compareDataWithFilterItems(dirData, filters)) {
              filteredDirectoryValues.push(dirData[source]);
            }
          });

          return filteredDirectoryValues;
        }

        return directory.filter((d) => {
          const { data, ...rest } = d;
          const dirData: Record<string, unknown> = { ...rest, ...data };

          return compareDataWithFilterItems(dirData, filters);
        });
      })();

      return sourceObj.isSourceFor ? processNestedSource(sourceObj.isSourceFor, value) : value;
    } else {
      const control = controlsMap.get(sourceObj.source);

      if (!control) {
        console.error(`cannot find corresponding control with fieldId ${sourceObj.source}`);
        return null;
      }

      if (!hasValue(control.value)) {
        return null;
      }

      if (sourceObj.pseudonim) {
        valuesByPseudonims[sourceObj.pseudonim] = control.value;
      }

      return sourceObj.isSourceFor ? processNestedSource(sourceObj.isSourceFor, control.value) : control.value;
    }
  }

  if (!Array.isArray(sources)) {
    return {
      value: getValueFromSources(sources),
      valuesByPseudonims,
    };
  } else {
    return {
      value: sources.map((source) => getValueFromSources(source)),
      valuesByPseudonims,
    };
  }
};
