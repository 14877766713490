import { ScopeDIProvider, requireService } from '@profgeosoft/di-ez';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { DirectoriesList, Sidebar } from 'src/components';
import { wrap } from 'src/packages/mobx-di/wrap';

import { DirectoryPageService } from '../directory/directory-page-service';

import styles from './directories.module.scss';

export const Directories = wrap(function Directories() {
  const navigate = useNavigate();
  const [isSideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const directoriesList = requireService('directoriesListService');
  const [directoryPage] = useState(() => new DirectoryPageService());

  const init = useCallback(() => ({ directoryPage: directoryPage }), [directoryPage]);

  useEffect(() => {
    return directoryPage.init(navigate);
  }, [directoriesList, directoryPage, navigate]);

  return (
    <ScopeDIProvider init={init}>
      <div className={styles.container}>
        <DirectoriesList />
        <div className={styles.contentWrapper}>
          <Outlet />
        </div>

        <Sidebar
          isOpened={isSideBarOpen}
          close={() => setSideBarOpen(false)}
          open={() => setSideBarOpen(true)}
          toggle={() => setSideBarOpen((prev) => !prev)}
        />
      </div>
    </ScopeDIProvider>
  );
});

declare module '@profgeosoft/di-ez' {
  export interface ServicesCollectionMap {
    directoryPage: DirectoryPageService;
  }
}
