import { action, computed, flow, makeObservable, observable } from 'mobx';

import { OperationAbortedError, ApiError } from 'src/packages/errors';
import { type TPrimitive, type TPrimitiveArray, type TOption, isPrimitive } from 'src/packages/types';
import { hasValue } from 'src/packages/utils/has-value';
import { isSavedDirectoryObject, type EditModeManager } from 'src/pages/directory/edit-mode-manager';

import type { ErrorsValidationErrorsStore } from 'src/pages/directory/server-validation-errors-store';
import type {
  DirectoriesListService,
  TDependentDirectory,
} from 'src/services/directories-list-service/directories-list-service';
import type {
  IDirectoriesStorage,
  TGetObjectsParams,
} from 'src/services/directories-storage-service/directories-storage-service';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { INotificationsService } from 'src/services/notifications-service';

export class DirectoryModelService {
  private readonly notificationsService: INotificationsService;
  private readonly directoriesList: DirectoriesListService;
  private readonly directoriesStorageService: IDirectoriesStorage;
  private readonly directoryService: MainDirectoryService;
  private readonly serverValidationStore: ErrorsValidationErrorsStore;
  private readonly editModeManager: EditModeManager;

  @observable private loadModelsAbortController: AbortController | null = null;
  @observable private models: Record<string, TPrimitive | TPrimitiveArray>[] = [];

  @observable isModelsLoading = false;
  @observable currentModelId: number | null = null;

  constructor(
    notificationsService: INotificationsService,
    directoriesList: DirectoriesListService,
    directoriesStorageService: IDirectoriesStorage,
    directoryService: MainDirectoryService,
    serverValidationStore: ErrorsValidationErrorsStore,
    editModeManager: EditModeManager,
  ) {
    this.notificationsService = notificationsService;
    this.directoriesList = directoriesList;
    this.directoriesStorageService = directoriesStorageService;
    this.directoryService = directoryService;
    this.serverValidationStore = serverValidationStore;
    this.editModeManager = editModeManager;

    makeObservable(this);
  }

  @computed
  get errorsCountByModelId(): Record<number, number> {
    const counter: Record<number, number> = {};
    const viewRefAttr = this.view?.refAttr;

    if (!this.directoryService.directoryName || !viewRefAttr || !hasValue(this.currentModelId)) {
      return {};
    }

    const errors = this.serverValidationStore.errors?.[this.directoryService.directoryName];
    const savedData = this.editModeManager.savedData?.[this.directoryService.directoryName];

    if (!errors || !savedData || typeof savedData !== 'object' || isSavedDirectoryObject(savedData)) {
      return {};
    }

    for (const modelId in savedData) {
      const { updatedRecords, newRecords } = savedData[modelId];
      const modelIdAsANumber = Number(modelId);

      for (const recordId in errors) {
        const record = updatedRecords[Number(recordId)] ?? newRecords[Number(recordId)];

        if (!record) {
          continue;
        }

        counter[modelIdAsANumber] = (counter[modelIdAsANumber] ?? 0) + errors[recordId].length;
      }
    }

    return counter;
  }

  @flow.bound
  private async *loadModels(params?: TGetObjectsParams) {
    const subDirectoryView = this.view;
    const directoryView = this.directoriesList.currentDirectoryView;

    if (!subDirectoryView || !directoryView) {
      return;
    }

    this.isModelsLoading = true;

    if (this.loadModelsAbortController) {
      this.loadModelsAbortController.abort();
    }

    const abortController = new AbortController();
    this.loadModelsAbortController = abortController;

    try {
      const models = await this.directoriesStorageService.loadObjects(directoryView.objectType, false);
      yield;

      this.models = models.map((model) => ({ ...model.data, id: model.id }));
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }
      console.error(e);
      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.data.failedToLoadModels');
    } finally {
      this.isModelsLoading = false;
      this.loadModelsAbortController = null;
    }
  }

  @action.bound
  loadTableData(): void {
    const view = this.view;

    if (!view || !hasValue(this.currentModelId)) {
      return;
    }

    const filter: Record<string, unknown> = {};

    filter[view.refAttr] = this.currentModelId;

    this.directoryService.loadData({ filterMap: filter });
  }

  get view(): TDependentDirectory | null {
    return this.directoriesList.currentSubDirectoryView;
  }

  @computed
  get modelsList(): TOption[] {
    const view = this.view;

    if (!view) {
      return [];
    }

    const models: TOption[] = [];

    for (const rawModel of this.models) {
      const label = rawModel[view.mainDisplayAttr];
      const value = rawModel[view.mainAttr];

      if (!isPrimitive(label)) {
        console.warn('label is not a primitive', label, rawModel);
        continue;
      }

      if (typeof value !== 'number') {
        console.warn('value is not a number', value);
        continue;
      }

      models.push({ label: label.toString(), value });
    }

    return models;
  }

  @action.bound
  setCurrentModel(id: number | null): boolean {
    if (id === this.currentModelId) {
      return false;
    }

    const directorySlice = this.directoryService;

    let isRecordsValid = true;

    [...directorySlice.newRecords, ...directorySlice.updatedRecords].forEach((record) => {
      if (!directorySlice.validationService.validateRecord(record)) {
        isRecordsValid = false;
      }
    });

    if (!isRecordsValid) {
      this.notificationsService.showErrorMessageT('directory:errors.validation.switchModelIdInvalidRecords');
      return false;
    }

    this.currentModelId = id;
    this.loadTableData();

    return true;
  }

  init = () => {
    this.loadModels();

    return () => {
      this.loadModelsAbortController?.abort();
    };
  };
}
