import { action, makeObservable, observable } from 'mobx';

import { debounce } from 'src/packages/utils/debounce';
import { hasValue } from 'src/packages/utils/has-value';
import { localStorageUtil } from 'src/packages/utils/local-storage-util';
import { serializeDirectoryRecordToSave } from 'src/services/directory-service/directory-service.utils';

import type { DirectoryModelService } from 'src/features/models-list/directory-model-service';
import type { DirectoriesListService } from 'src/services/directories-list-service/directories-list-service';
import type { MainDirectoryService } from 'src/services/directory-service';

export type TSavedEditModeObject = {
  newRecords: Record<number, Record<string, unknown>>;
  updatedRecords: Record<number, Record<string, unknown>>;
};

type TSavedEditModeData = {
  [key: string]: TSavedDirectoryData | string | number | undefined;
  lastVisitedDirectory: string;
  lastVisitedSubDirectory?: string;
  lastVisitedModelId?: string | number;
};

type TSavedDirectoryData = TSavedEditModeObject | Record<string | number, TSavedEditModeObject>;

export const EDIT_MODE_DATA_KEY = 'savedEditModeData';

export const isSavedDirectoryObject = (obj: Object): obj is TSavedEditModeObject => {
  return 'newRecords' in obj && 'updatedRecords' in obj;
};

const isSavedEditModeData = (obj: unknown): obj is TSavedEditModeData => {
  if (typeof obj !== 'object' || !hasValue(obj)) {
    return false;
  }

  return !('newRecords' in obj) && !('updatedRecords' in obj) && 'lastVisitedDirectory' in obj;
};

export class EditModeManager {
  private readonly directoriesList: DirectoriesListService;
  @observable savedData: TSavedEditModeData | null = null;

  constructor(directoriesList: DirectoriesListService) {
    this.directoriesList = directoriesList;

    makeObservable(this);
  }

  @action.bound
  private _saveEditModeData(
    currentDirectory: MainDirectoryService,
    directoryModelService?: DirectoryModelService,
  ): void {
    const newRecords = currentDirectory?.newRecords ?? [];
    const updatedRecords = currentDirectory?.updatedRecords ?? [];

    const directoryName = this.directoriesList.currentDirectory;

    if (!hasValue(directoryName)) {
      return;
    }

    const subDirectoryName = this.directoriesList.currentSubDirectory;
    const modelId = directoryModelService?.currentModelId;

    const saveDirectoryName = subDirectoryName ?? directoryName;

    if (!hasValue(saveDirectoryName)) {
      console.warn('current directory name is not presented');
      return;
    }

    const data: TSavedEditModeObject = {
      newRecords: newRecords.reduce((acc: Record<number, Record<string, unknown>>, record) => {
        acc[record.recordId] = serializeDirectoryRecordToSave(record);
        return acc;
      }, {}),
      updatedRecords: updatedRecords.reduce((acc: Record<number, Record<string, unknown>>, record) => {
        acc[record.recordId] = serializeDirectoryRecordToSave(record);
        return acc;
      }, {}),
    };

    const directorySavedData: TSavedDirectoryData = (() => {
      const existingData = (() => {
        const savedDirectoryData = this.savedData?.[saveDirectoryName];

        if (!hasValue(savedDirectoryData) || typeof savedDirectoryData !== 'object') {
          return {};
        }

        return savedDirectoryData;
      })();

      if (hasValue(modelId)) {
        return {
          ...existingData,
          [modelId]: data,
        };
      } else {
        return {
          ...existingData,
          ...data,
        };
      }
    })();

    const updatedSavedData: TSavedEditModeData = {
      [saveDirectoryName]: directorySavedData,
      lastVisitedDirectory: directoryName,
      lastVisitedSubDirectory: subDirectoryName ?? undefined,
      lastVisitedModelId: modelId ?? undefined,
    };

    this.savedData = updatedSavedData;
    localStorageUtil.set(EDIT_MODE_DATA_KEY, updatedSavedData);
  }

  private _getSavedEditModeData(
    currentDirectory: MainDirectoryService,
    modelId?: string | number,
  ): TSavedEditModeObject | null {
    const directorySavedData = (() => {
      const object = this.savedData?.[currentDirectory.directoryName];

      if (!hasValue(object) || typeof object !== 'object') {
        return null;
      }

      return object;
    })();

    if (!directorySavedData) {
      return null;
    }

    if (!hasValue(modelId)) {
      if (isSavedDirectoryObject(directorySavedData)) {
        return directorySavedData;
      }

      console.error('wrong saved data type', directorySavedData);
      return null;
    } else {
      if (!isSavedDirectoryObject(directorySavedData)) {
        const modelSavedData = directorySavedData[modelId];

        return modelSavedData ?? null;
      }

      return null;
    }
  }

  @action.bound
  removeSavedData() {
    this.savedData = null;
    localStorageUtil.delete(EDIT_MODE_DATA_KEY);
  }

  @action.bound
  updateCommonEditModeData({
    directory,
    subDirectory,
    modelId,
  }: {
    directory: string;
    subDirectory?: string;
    modelId?: string | number;
  }): void {
    const updatedSavedData = {
      ...this.savedData,
      lastVisitedDirectory: directory,
      lastVisitedSubDirectory: subDirectory,
      lastVisitedModelId: modelId,
    };

    this.savedData = updatedSavedData;
    localStorageUtil.set(EDIT_MODE_DATA_KEY, updatedSavedData);
  }

  saveEditModeData = debounce(this._saveEditModeData.bind(this), 500);
  getSavedEditModeData = this._getSavedEditModeData.bind(this);

  @action.bound
  init(): TSavedEditModeData | null {
    const savedInLSData = localStorageUtil.get(EDIT_MODE_DATA_KEY);

    if (isSavedEditModeData(savedInLSData)) {
      this.savedData = savedInLSData;

      return savedInLSData;
    }

    return null;
  }
}
